"use client";
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from "react";

type DateRange = [Date | null, Date | null];

interface HotelSearchState {
  location: string;
  dateRange: DateRange;
  rooms: number;
  pax: number[];
  mealPlans: string[];
  searchQuery: string;
  area: string[];
  starRating: number[];
}

interface HotelSearchContextType extends HotelSearchState {
  updateSearch: (params: Partial<HotelSearchState>) => void;
}

const initialState: HotelSearchState = {
  location: "",
  dateRange: [null, null],
  rooms: 1,
  pax: [],
  mealPlans: [],
  searchQuery: "",
  area: [],
  starRating: [],
};

const HotelSearchContext = createContext<HotelSearchContextType | undefined>(
  undefined
);

function useHotelSearchState(): HotelSearchContextType {
  const [state, setState] = useState<HotelSearchState>(initialState);

  const updateSearch = useCallback((params: Partial<HotelSearchState>) => {
    setState((prev) => ({ ...prev, ...params }));
  }, []);

  return {
    ...state,
    updateSearch,
  };
}

export function HotelSearchProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const value = useHotelSearchState();
  return (
    <HotelSearchContext.Provider value={value}>
      {children}
    </HotelSearchContext.Provider>
  );
}

export function useHotelSearch(): HotelSearchContextType {
  const context = useContext(HotelSearchContext);
  if (context === undefined) {
    throw new Error("useHotelSearch must be used within a HotelSearchProvider");
  }
  return context;
}
